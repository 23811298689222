import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

import logo from '../../images/banner_logo.svg'

const Banner = () => 
  <div className="banner">
    <Container>
      <Row>
        <Col md="7" className="col-content">
          <h1>La boite à outils</h1>
          <p className="subtitle text-blue mb-5">Services et Bricolage</p>
          <p className="big mb-5">D’un naturel serviable et disponible, je mets mes qualités à votre service pour différents types de petits travaux et dépannages. </p>
          <Link className="button button-blue mt-5" to="contact">Me contacter</Link>
        </Col>

        <Col md="5" className="col-img text-center">
          <img className="banner-logo" alt="Logo la Boite à Outils" src={logo}/>
        </Col>
      </Row>
    </Container>
  </div>


export default Banner