import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import logo from '../../images/logo.svg'
import Social from '../Social'

const BottomInfos = () => 
  <section className="bg-blue bottom-infos padding-section">
    <Container>
      <Row>
        <Col sm="12" md="6" className="left-column text-center mb-5">
          <div className="bottom-logo">
            <div className="logo-container">
              <img className="image" alt="Le logo de la boite à outils" src={logo} />
            </div>
            <h2>La boite<br/>à outils</h2>
          </div>
        </Col>
        <Col sm="12" md="6" className="text-center">
          <Social />
        </Col>
      </Row>
    </Container>
  </section>

export default BottomInfos

