import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql} from 'gatsby'
import { detect } from 'detect-browser'


import Header from '../Header'
import Footer from '../Footer'

import '../../css/styles.css'

const Layout = ({ children }) => {

    const browser = detect()
    let paralax = false
    if(browser.name === 'chrome' || browser.name === 'firefox'){
      paralax = true
    }

    return( 
      <StaticQuery
        query={graphql`
          query homeLayoutQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: data.site.siteMetadata.description },
                { name: 'keywords', content: data.site.siteMetadata.keywords },
              ]}
            >
              <html lang="fr" />
            </Helmet>
            
            <div className={`home-layout-wrapper ${(paralax ? 'paralax-wrapper' : '')}`}>
              <Header/>
              {children}
              <Footer />
            </div>
          </>
        )}
      />
    )

}
 

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
