import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Reveal from 'react-reveal/Reveal'
import Masonry from 'react-masonry-css'


export default class ServicesCards extends React.Component {
  render() {
    return (
    	  <StaticQuery
		    query={graphql`
		      	query worksQuery {
		      		allContentfulRealisation {
					  	edges {
					    	node {
						      	id
						      	titre
						      	slug
						      	date
						      	photo {
					        		id
					        		  sizes(maxWidth: 500) {
							          ...GatsbyContentfulSizes_tracedSVG
							        }
					      		}					      
					    	}
					  	}
					}
		      	}
		    `}
		    render={data => {
		    	const works = data.allContentfulRealisation.edges

		    	const breakpointColumnsObj = {
			  		default: 3,
			  		991: 2,
			  		767: 1
				};

		    	return(
		    		<Masonry
						breakpointCols={breakpointColumnsObj}
					    className="masonry-grid"
				    	columnClassName="masonry-grid_column">
			    		{
			    			works.map((w, key) => {
			    				return(
			    				 <Reveal key={key} effect="img-fadeInUp">
			    					<div className="work-tile-container">
			  							<div className="image-container">
			  								<Img sizes={w.node.photo.sizes}/>
			  							</div>
			  							<div className="title-container">
			    							<p className="title">{w.node.titre}</p>
			    						</div>
		  							</div>
	  							</Reveal>
	  							)
			    			} 
			    				
			    			)
			    		}	     	
					</Masonry>
		    	)
		    }}
		  />
    );
  }
}
						

