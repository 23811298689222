import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link, graphql } from 'gatsby'

import Img from 'gatsby-image'
import Reveal from 'react-reveal/Reveal'
import config from 'react-reveal/globals'

import Layout from '../components/layouts/homeLayout'
import BottomInfos from '../components/BottomInfos'
import Banner from '../components/Banner'
import ServicesCards from '../components/ServicesCards'
import WorksMansonry from '../components/WorksMansonry'


config({ ssrFadeout: true })

class IndexPage extends React.Component{


	render(){
		const { data } = this.props

		return(
			 <Layout>
  				<Banner />
	  			<section className="intro">
				  		<div className="contenu-image">
					  		<Container>
								<Row>
					  				<Col md='6'>
										<Img
											fluid={data.personImage.childImageSharp.fluid}
											title={`Vincent Richard`}
											alt="photo de Vincent Richard"
											style={{height: 330, width: 330, margin: 'auto'}}
										/>
					  				</Col>
								</Row>
							</Container>
					  	</div>
					  	<div className="contenu-text">
					 		<Container>
					  			<Row>
					  				<Col md={{size: 6, offset: 6}}>
							  			<h2 className="mb-5 mt-5">Qui suis-je ?</h2>
							  			<p className="big">
							  			Vincent, 57 ans, je suis à votre disposition pour tout coup de main, bricolage, dépannage et réparation.<br/>
							  			Le bricolage est ma passion, souriant et à l'écoute, j'aime mettre mes compétences à votre service.
							  			</p>
									</Col>
								</Row>
							</Container>
					  	</div>
	  			</section>
	  				
  				
  				<section className="bg-blue space-section padding-section">
  					<Container className="pb-5">
  						<Row>
  							<Col>
		  						<h2 className="text-center mt-5">Mes offres de services</h2>
		  						<div className="small-container my-5">
		     						<p className="text-center big mb-5">
		     							Une question ?  Un problème ?
										Un seul réflexe : la Boite à Outils
									</p>
		     					</div>
	     					</Col>
     					</Row>
     					
  						<Reveal effect="tile-fadeInUp">
						    <ServicesCards />
  						</Reveal>
  						<Row>
  							<Col className="text-center mt-5">
  								<Link className="button button-blue" to="services">Mes services</Link>
  							</Col>
  						</Row>
  					</Container>
  				</section>

  				
  				<section className="space-section padding-section">
  					<Container>
	  					<h2 className="text-center mb-5">Mes derniers services</h2>
	  					<div className="small-container">
	  						<p className="text-center big">Quelques exemples de travaux déjà réalisés </p>
	  					</div>

	  					<Row className="justify-content-center">
	  						<Col xs="11" sm="10" md="12" className="d-flex justify-content-center">
	  							<WorksMansonry />
	  						</Col>
	  					</Row>

	  					

  					</Container>
  				</section>
  				


  				<section className="space-section padding-section rounded-bottom mt-5 pt-5">
  					<Container>
	  					<h2 className="text-center my-5">Des questions ? Des renseignements ? </h2>
	  					<Row>
  							<Col className="text-center mt-5">
  								<Link className="button button-orange" to="contact">Me contacter</Link>
  							</Col>
  						</Row>
  					</Container>
  				</section>
  				<BottomInfos />
  			</Layout>
		)
	}
}

export default IndexPage

export const query = graphql`
  query {
  	personImage: file(relativePath: { regex: "/person/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    worksImages: allFile(filter: {relativePath: {regex: "/works//"}}) {
	  edges {
	    node {
	      id
	      childImageSharp {
	        fluid(maxWidth: 500) {
	          ...GatsbyImageSharpFluid
	        }
	      }
      	}
      }
  	}
  }
`

