import React from 'react'
import { StaticQuery, navigate, graphql } from 'gatsby'
import { Row, Col } from 'reactstrap'
import Img from 'gatsby-image'


export default class ServicesCards extends React.Component {
  render() {
    return (
    	  <StaticQuery
		    query={graphql`
		      	query cardsQuery {
		      		allContentfulServices{
		      			edges{
		      				node{
		      					id
		      					slug
		      					picto{
		      						sizes(maxWidth: 100){
							          ...GatsbyContentfulSizes
							        } 
		      					}
		      					titre
		      					couleur
		      					ordre
		      				}
		      			}
		      		}
		      	}
		    `}
		    render={data => {
		    	
		    	const cards = data.allContentfulServices.edges.sort((a, b) => a.node.ordre - b.node.ordre)
		    	return(
		    		<Row className="my-5">
		    		{
		    			cards.map((c, key) => 
		    				<Col key={key} onClick={() => navigate(`services/${c.node.slug}`)} sm="6" md="6" lg="3" className="mb-5">
								<div className="tile-link-container reveal" id={`tile-${key}`}>
									<div className="tile">
										<div className="tile-image-container">
											<Img className="tile-image" sizes={c.node.picto.sizes} />
										</div>
										<h3>{c.node.titre}</h3>
										<span className={c.node.couleur}>En savoir plus</span>
									</div>
								</div>
							</Col>
		    			)

		    		}	     	
					</Row>
		    	)
		    }}
		  />
    );
  }
}

