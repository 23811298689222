import React from 'react';

const Social = () => 
  <ul className="social-list">
      <li className="person"><p>Vincent RICHARD</p></li>
      <li className="phone"><a href="tel:+33625465790">06 25 46 57 90</a></li>
      <li className="mail"><a href="mailto:laboiteoutils@orange.fr">laboiteoutils@orange.fr</a></li>
      <li className="facebook"><a href="https://www.facebook.com/LaboiteaoutilsServices/" target="_blank" rel="noopener noreferrer">faites un tour sur ma page Facebook !</a></li>
  </ul>

export default Social


